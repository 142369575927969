import React from "react";
import Navbar from "../../components/Navbar/Navbar";
import Footer from "../../components/Footer/Footer";
import CursorPoint from "../../components/CursorPointer";
import './Privacy.css';


const Privacy = () => {
  return (
    <div>
      <CursorPoint />
      <Navbar />
      <div className="privacy-parent">
        <div>
          <h1 className="privacy-heading">Privacy Policy</h1>
          <p>
            Coach Evangelin (“Company,” “we,” or “us”) respects your privacy and
            is committed to protecting it through this Privacy Policy.
          </p>
          <p>
            This Privacy Policy governs your access to and use of
            <a href="https://evangelin.co.in"> https://evangelin.co.in/</a>,
            including any content, functionality and services offered on or
            through{" "}
            <a href="https://evangelin.co.in">https://evangelin.co.in </a> (the
            “Website”), whether as a guest or a registered user.
          </p>
          <p>
            When accessing the Website, the Company will learn certain
            information about you, both automatically and through voluntary
            actions you may take, during your visit. This policy applies to
            information we collect on the Website and in email, text, or other
            electronic messages between you and the Website.
          </p>
          <p>
            Please read the Privacy Policy carefully before you start to use the
            Website. By using the Website or by clicking to accept or agree to
            the Terms of Use when this option is made available to you, you
            accept and agree to be bound and abide by the Privacy Policy. If you
            do not want to agree to the Privacy Policy, you must not access or
            use the Website.
          </p>
        </div>

        <div>
          <h2>Children Under The Age Of 13</h2>
          <p>
            Our Website is not intended for children under 13 years of age. No
            one under age 13 may provide any information to or on the Website.
            We do not knowingly collect personal information from children under
            13. If you are under 13, do not use or provide any information on
            this Website or on or through any of its features/register on the
            Website, make any purchases through the Website, use any of the
            interactive or public comment features of this Website or provide
            any information about yourself to us, including your name, address,
            telephone number, email address, or any screen name or user name you
            may use.
          </p>
          <p>
            If we learn we have collected or received personal information from
            a child under 13 without verification of parental consent, we will
            delete that information. If you believe we might have any
            information from or about a child under 13, please contact us at
            <a href="mailto:team@evangelin.co.in"> team@evangelin.co.in</a>.
          </p>
        </div>

        <div>
          <h2>Information We Collect About You</h2>
          <p>
            When you access the Website, the Company will learn certain
            information about you during your visit.
          </p>
          <p>
            Information You Provide To Us. The Website provides various places
            for users to provide information. We collect information that users
            provide by filling out forms on the Website, communicating with us
            via contact forms, responding to surveys, search queries on our
            search feature, providing comments or other feedback, and providing
            information when ordering a product or service via the Website.
          </p>
          <p>
            We use information you provide to us to deliver the requested
            product and/or service, to improve our overall performance, and to
            provide you with offers, promotions, and information.
          </p>
          <p>
            Information We Collect Through Automatic Data Collection Technology.
            As you navigate through our Website, we may use automatic data
            collection technologies including Google Analytics to collect
            certain information about your equipment, browsing actions, and
            patterns. This will generally include information about your
            location, your traffic pattern through our website, and any
            communications between your computer and our Website. Among other
            things, we will collect data about the type of computer you use,
            your Internet connection, your IP address, your operating system,
            and your browser type.
          </p>
          <p>
            The information we collect automatically is used for statistical
            data and will not include personal information. We use this data to
            improve our Website and our service offerings. To the extent that
            you voluntarily provide personal information to us, our systems will
            associate the automatically collected information with your personal
            information.
          </p>
        </div>

        <div>
          <h2>Use of Cookies And Pixels</h2>
          <p>
            Similar to other commercial websites, our website utilizes a
            standard technology called “cookies” and server logs to collect
            information about how our site is used. Information gathered through
            cookies and server logs may include the date and time of visits, the
            pages viewed, time spent at our site, and the websites visited just
            before and just after our own, as well as your IP address.
          </p>
          <p>
            A cookie is a very small text document, which often includes an
            anonymous unique identifier. When you visit a website, that site’s
            computer asks your computer for permission to store this file in a
            part of your hard drive specifically designated for cookies. Each
            website can send its own cookie to your browser if your browser’s
            preferences allow it, but (to protect your privacy) your browser
            only permits a website to access the cookies it has already sent to
            you, not the cookies sent to you by other sites.
          </p>
          <p>
            The Company reserves the right to use technological equivalents of
            cookies, including social media pixels. These pixels allow social
            media sites to track visitors to outside websites so as to tailor
            advertising messages users see while visiting that social media
            website. The Company reserves the right to use these pixels in
            compliance with the policies of the various social media sites.​
          </p>
          <p>
            California law requires us to let you know whether we honor “Do Not
            Track” requests set by your browser. Unfortunately, there is no
            industry or legal standard (or easy technological solution) about
            how to handle these responses. Thus, we don’t currently respond to
            Do Not Track signals at this time.
          </p>
        </div>

        <div>
          <h2>Third Party Use Of Cookies</h2>
          <p>
            Some content or applications, including advertisements, on the
            Website are served by third-parties, including advertisers, ad
            networks and servers, content providers, and application providers.
            These third parties may use cookies alone or in conjunction with web
            beacons or other tracking technologies to collect information about
            you when you use our website. The information they collect may be
            associated with your personal information or they may collect
            information, including personal information, about your online
            activities over time and across different websites and other online
            services. They may use this information to provide you with
            interest-based (behavioral) advertising or other targeted content.
          </p>
          <p>
            We do not control these third parties’ tracking technologies or how
            they may be used. If you have any questions about an advertisement
            or other targeted content, you should contact the responsible
            provider directly.
          </p>
        </div>

        <div>
          <h2>Email Information</h2>
          <p>
            If you choose to correspond with us through email, we may retain the
            content of your email messages together with your email address and
            our responses. We provide the same protections for these electronic
            communications that we employ in the maintenance of information
            received online, mail, and telephone. This also applies when you
            register for our website, sign up through any of our forms using
            your email address or make a purchase on this site. For further
            information see the email policies below.
          </p>
        </div>

        <div>
          <h2>Email Information</h2>
          <p>
            If you choose to correspond with us through email, we may retain the
            content of your email messages together with your email address and
            our responses. We provide the same protections for these electronic
            communications that we employ in the maintenance of information
            received online, mail, and telephone. This also applies when you
            register for our website, sign up through any of our forms using
            your email address or make a purchase on this site. For further
            information see the email policies below.
          </p>
        </div>

        <div>
          <h2>Email Policies</h2>
          <p>
            We are committed to keeping your e-mail address confidential. We do
            not sell, rent, or lease our subscription lists to third parties,
            and will not disclose your email address to any third parties except
            as allowed in the section titled Disclosure of Your Information.
          </p>
          <p>
            We will maintain the information you send via e-mail in accordance
            with applicable federal law.
          </p>
          <p>
            In compliance with the CAN-SPAM Act, all e-mails sent from our
            organization will clearly state who the e-mail is from and provide
            clear information on how to contact the sender. In addition, all
            e-mail messages will also contain concise information on how to
            remove yourself from our mailing list so that you receive no further
            e-mail communication from us.
          </p>
          <p>
            Our emails provide users the opportunity to opt-out of receiving
            communications from us and our partners by reading the unsubscribe
            instructions located at the bottom of any e-mail they receive from
            us at anytime.
          </p>
          <p>
            Users who no longer wish to receive our newsletter or promotional
            materials may opt-out of receiving these communications by clicking
            on the unsubscribe link in the e-mail.
          </p>
        </div>

        <div>
          <h2>How And Why We Collect Information</h2>
          <p>
            The Company collects your information in order to record and support
            your participation in the activities you select. If you register to
            download a book or resources, sign up for our newsletter, and/or
            purchase a product from us, we collect your information. We use this
            information to track your preferences and to keep you informed about
            the products and services you have selected to receive and any
            related products and/or services. As a visitor to this Website, you
            can engage in most activities without providing any personal
            information. It is only when you seek to download resources and/or
            register for services that you are required to provide information.
          </p>
          <p>
            If you are outside the Indian Union and opt to receive any free
            resources, participate in any free training programs, register for a
            webinar, register for a live event, register for a seminar, or
            purchase any products sold by the Company on this Website, we will
            automatically enroll ​you to receive our free email newsletter. If
            you do not wish to receive this newsletter, you can unsubscribe
            anytime. We include an “unsubscribe” link at the bottom of every
            email we send. If you ever have trouble unsubscribing, you can send
            an email to <a href="mailto:team@evangelin.co.in"> team@evangelin.co.in</a> requesting to unsubscribe from
            future emails.
          </p>
          <p>
            If you are in the Indian Union and opt to receive any free
            resources, participate in any free training programs, register for a
            webinar, register for a live event, register for a seminar, or
            purchase any products sold by the Company on this Website, we will
            only enroll ​you to receive our free email newsletter if you
            affirmatively consent to it. If you do not wish to receive this
            newsletter, you can unsubscribe anytime. We include an “unsubscribe”
            link at the bottom of every email we send. If you ever have trouble
            unsubscribing, you can send an email to <a href="mailto:team@evangelin.co.in"> team@evangelin.co.in </a>
            requesting to unsubscribe from future emails.
          </p>
        </div>

        <div>
          <h2>How Do We Use the Information That You Provide to Us?</h2>
          <p>
            We use personal information for purposes of presenting our Website
            and its contents to you, providing you with information, providing
            you with offers for products and services, providing you with
            information about your subscriptions and products, carrying out any
            contract between you and the Company, administering our business
            activities, providing customer service, and making available other
            items and services to our customers and prospective customers.
          </p>
          <p>
            From time-to-time, we may use the information you provide to us to
            make you offers to purchase products and services provided by third
            parties in exchange for a commission to be paid to us by such third
            parties. Should you opt to take part in such promotions, the third
            parties will receive your information.
          </p>
          <p>
            From time-to-time, we may use the information you provide to us to
            display advertisements to you that are tailored to your personal
            characteristics, interests, and activities.
          </p>
        </div>

        <div>
          <h2>Disclosure Of Your Information</h2>
          <p>
            As a general rule, we do not sell, rent, lease or otherwise transfer
            any information collected whether automatically or through your
            voluntary action.
          </p>
          <p>
            We may disclose your personal information to our subsidiaries,
            affiliates, and service providers for the purpose of providing our
            services to you.
          </p>
          <p>
            We may disclose your personal information to a third party,
            including a lawyer or collection agency, when necessary to enforce
            our terms of service or any other agreement between you and the
            Company.
          </p>
          <p>
            We may provide your information to any successor in interest in the
            event of a merger, divestiture, restructuring, reorganization,
            dissolution, or other sale or transfer of some or all of the
            Company’s assets and/or business.
          </p>
          <p>
            We may disclose information when legally compelled to do so, in
            other words, when we, in good faith, believe that the law requires
            it or for the protection of our legal rights or when compelled by a
            court or other governmental entity to do so.
          </p>
        </div>

        <div>
          <h2>
            How Do We Protect Your Information and Secure Information
            Transmissions?
          </h2>
          <p>
            We employ commercially reasonable methods to ensure the security of
            the information you provide to us and the information we collect
            automatically. This includes using standard security protocols and
            working only with reputable third-party vendors.
          </p>
          <p>
            Email is not recognized as a secure medium of communication. For
            this reason, we request that you do not send private information to
            us by email. However, doing so is allowed, but at your own risk.
            Some of the information you may enter on our website may be
            transmitted securely via a secure medium known as Secure Sockets
            Layer, or SSL. Credit Card information and other sensitive
            information is never transmitted via email.
          </p>
          <p>
            The Company may use software programs to create summary statistics,
            which are used for such purposes as assessing the number of visitors
            to the different sections of our site, what information is of most
            and least interest, determining technical design specifications, and
            identifying system performance or problem areas.
          </p>
          <p>
            For site security purposes and to ensure that this service remains
            available to all users, the Company uses software programs to
            monitor network traffic to identify unauthorized attempts to upload
            or change information, or otherwise cause damage.
          </p>
        </div>

        <div>
          <h2>Policy Changes</h2>
          <p>
            It is our policy to post any changes we make to our privacy policy
            on this page. If we make material changes to how we treat our users’
            personal information, we will notify you by email to the email
            address specified in your account and/or through a notice on the
            Website home page. The date the privacy policy was last revised is
            identified at the bottom of the page. You are responsible for
            ensuring we have an up-to-date active and deliverable email address
            for you, and for periodically visiting our Website and this privacy
            policy to check for any changes.
          </p>
        </div>
        <div>
            <h2>Contact Information
            </h2>
            <p>For questions or concerns, contact us at:
            </p>
            <p><strong>Email:</strong> <a href="mailto:team@evangelin.co.in">team@evangelin.co.in </a></p>
            <p><strong>Phone:</strong> +91 96199 05777
            </p>
            
            <p>By engaging in our services, you acknowledge that you have read, understood, and agree to these Privacy Policy.
            </p>
          </div>
      </div>
      <Footer />
    </div>
  );
};

export default Privacy;
