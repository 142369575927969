import React from 'react';
import './Contact.css';
import ContactImage from '../../assets/contact/contact.jpg';
import emailjs from '@emailjs/browser'
import {useRef} from 'react';
import { useState } from 'react';


export default function Contact() {
    const [firstName , setFirstName] = useState("");
    const [firstPhone , setFirstPhone] = useState("");
    const [firstEmail , setFirstEmail] = useState("");
    const [message , setMessage] = useState("");



    const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_ef2f7lu', 'template_v3ohxz5', form.current, {
        publicKey: 'qjqphS-BxUVpoz2mT',
      })
      .then(
        () => {
          console.log('SUCCESS!');
          setFirstName("");
          setFirstPhone("");
          setFirstEmail("");
          setMessage("");
          
        },
        (error) => {
          console.log('FAILED...', error.text);
        },
      );
  };

  


  return (
    <>
        <section id="contact">
            <div className="container">
                <div className="contact_wrapper">
                    <div className="contact_col">
                        <div className="contact_image">
                            <img src={ContactImage} alt="Contact"/>
                        </div>
                    </div>
                    <div className="contact_col">
                        <h2>Contact US</h2>
                        <form ref={form} onSubmit={sendEmail}>
                            <div className="input_wrapper">
                                <input type="text" className="form-control" placeholder="Your Name..." name='from_name' value={firstName} onChange={(e) => setFirstName(e.target.value)} autocomplete="off"/>
                            </div>
                            <div className="input_wrapper">
                                <input type="text" className="form-control" placeholder="Your Phone..." name='from_phone' value={firstPhone} onChange={(e) => setFirstPhone(e.target.value)} autocomplete="off"/>
                            </div>
                            <div className="input_wrapper">
                                <input type="email" className="form-control" placeholder="Your Email..." name='from_email' value={firstEmail} onChange={(e) => setFirstEmail(e.target.value)} autocomplete="off"/>
                            </div>
                            <div className="input_wrapper">
                                <textarea placeholder="Write a Message..." name='message' value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                            </div>
                            <div className="btn_wrapper">
                                <button type="submit" className="btn">
                                    Submit
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </section>
    </>
  )
}
