import React, { useRef } from 'react'
import react, {useState, useEffect} from 'react';
import './TestModel.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faXmark } from '@fortawesome/free-solid-svg-icons';


const faXmarkIcon = <FontAwesomeIcon icon={faXmark} />


// var close = document.querySelector("#closebanner");
// close.addEventListener('click', function(){
//     document.querySelector(".Model").style.display = "none";
// });

export default function TestModel({onClose}) {
    useEffect(()=>{
        document.body.style.overflowY = "hidden";

        return () => {
          document.body.style.overflowY = "scroll";
        };
      },[]);

      const modelRef = useRef();

      const closeBanner = (e) => {
        if(modelRef.current === e.target){
            onClose();
        }
      }

  return (
    <div className='Model' ref={modelRef} onClick={closeBanner}>
        <div className='ModelBanner'>
            <button id='closebanner' onClick={onClose}>{faXmarkIcon}</button>
            <div className='banner'>
                <h2 className='bannerhead'>Download Free E-Book</h2>
                <p className='bannertext'>Want to learn to more, How to crack business developement course</p>
                {/* <form action="">
                    <input 
                        type="email"
                        placeholder='Enter Your Email'
                        required
                        className='bannerinput'
                    />
                    <a href='https://test.evangelin.co.in/'><button className='enrollnow'>Enroll Now</button></a>
                </form> */}
                <input 
                        type="email"
                        placeholder='Enter Your Email'
                        required
                        className='bannerinput'
                    />
                    <a href='https://test.evangelin.co.in/'><button className='enrollnow'>Enroll Now</button></a>
            </div>
            
        </div>
    </div>

  )
}
