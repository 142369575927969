import React from 'react';
import './Services.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {  faChartBar, faCopy, faMarker, faUserGear, faCoins, faBuilding, faMoneyCheckDollar, faHeadset, faAddressCard, faClipboardQuestion, faArrowUpRightDots, faBullseye} from '@fortawesome/free-solid-svg-icons';

export default function Services() {

    // Icons
    const faHeadsetIcon = <FontAwesomeIcon icon={faHeadset} />
    const faBuildingIcon = <FontAwesomeIcon icon={faBuilding} />
    const faMoneyCheckDollarIcon = <FontAwesomeIcon icon={faMoneyCheckDollar} />
    const faChartBarIcon = <FontAwesomeIcon icon={faChartBar} />
    const faAddressCardIcon = <FontAwesomeIcon icon={faAddressCard} />
    const faClipboardQuestionIcon = <FontAwesomeIcon icon={faClipboardQuestion} />
    const faUserGearIcon = <FontAwesomeIcon icon={faUserGear} />
    const faCoinsIcon = <FontAwesomeIcon icon={faCoins} />
    const faArrowUpRightDotsIcon = <FontAwesomeIcon icon={faArrowUpRightDots} />
    const faBullseyeIcon = <FontAwesomeIcon icon={faBullseye} />


    
  return (
    <>
     <div id="services">
        <div className="container">
            <div className="title_headling">
                <h3>Coaching Services</h3>
                <p>Customized programs designed to elevate your journey towards Professional & Financial excellence in India.</p>
            </div>
            <div className="service_wrapper">
                <div className="service_box">
                    <div className="service_icon blue_icon">{faHeadsetIcon}</div>
                    <h4 className="number">01</h4>
                    <div className="service_content">
                        <h5>Consultation</h5>
                        <p>Our 5 step process in consultation gives result every single time, we first measure your metrics and create a strategy for your business which will yield result.</p>
                        <a href="/" className="read">Read more</a>
                    </div>
                </div> 
                <div className="service_box">
                    <div className="service_icon">{faBuildingIcon}</div>
                    <h4 className="number">02</h4>
                    <div className="service_content">
                        <h5>Business Tranformation</h5>
                        <p>Your team mindset through our unique tools, Implement the transformative step & execute line of action through team members have complete control of your business.</p>
                        <a href="/" className="read">Read more</a>
                    </div>
                </div>
                <div className="service_box">
                    <div className="service_icon green_icon">{faMoneyCheckDollarIcon}</div>
                    <h4 className="number">03</h4>
                    <div className="service_content">
                        <h5>Money Intellegence</h5>
                        <p>Handling Money is a combustion task, worry know more with Evan you can fix your money mindset, Book 60 Minute Session with Evan.</p>
                        <a href="https://wa.me/+919619905777?text=RFQ%20Enquiry%20-%20Coach%20Evangelin%20please%20send%20me%20quick%20Quotes" className="read">Chat Now!</a>
                    </div>
                </div>
                <div className="service_box">
                    <div className="service_icon">{faChartBarIcon}</div>
                    <h4 className="number">04</h4>
                    <div className="service_content">
                        <h5>Unstoppable Youth</h5>
                        <p>Everyone has dreams, but self-doubt often holds us back. Our coaching system can help you achieve your goals and become the person you aspire to be.</p>
                        <a href="/pdf/business-transformative-brochure" className="read">Read more</a>
                    </div>
                </div>
                <div className="service_box">
                    <div className="service_icon green_icon">{faArrowUpRightDotsIcon}</div>
                    <h4 className="number">05</h4>
                    <div className="service_content">
                        <h5>Unstoppable You</h5>
                        <p>We will a create action plan for your goals so that you live a fulfilling life. Our coaching system can help you achieve your goals and lead in life.</p>
                        <a href="/" className="read">Read more</a>
                    </div>
                </div>
                <div className="service_box">
                    <div className="service_icon blue_icon">{faBullseyeIcon}</div>
                    <h4 className="number">06</h4>
                    <div className="service_content">
                        <h5>Goal Setting</h5>
                        <p>Book a 60 minute session with Evan and attend clarity for your goal. What are you waiting.</p>
                        <a href="https://calendly.com/evanyourcoach/meeting-with-coach-evan" className="read">Book Now</a>
                    </div>
                </div>
            </div>
        </div>
     </div>
    </>
  )
}
