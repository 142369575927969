import "./App.css";
import Navbar from "./components/Navbar/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Services from "./components/Services/Services";
import Blog from "./components/Blog/Blog";
import Contact from "./components/Contact/Contact";
import Footer from "./components/Footer/Footer";
import Marketing from "./components/Marketing/Marketing"
import CursorPoint from "./components/CursorPointer";
import TestModel from "./components/Test Banner/TestModel";
import { useEffect } from "react";

import { faL } from "@fortawesome/free-solid-svg-icons";

// stop scrolling when popup enable
function App() {


  return (
    <div className="App">
      {/* Components */}
      <CursorPoint />
      <Navbar />
      {/* <TestModel /> */}
      <Home />
      <About />
      <Services />
      <Marketing />
      <Blog />
      <Contact />
      {/* <Privacy /> */}
      <Footer />
    </div>
  );
}

export default App;
