import React from 'react';
import './Blog.css';
import BlogImage from '../../assets/blog/blog.jpg';

export default function Blog() {
  return (
    <>
    <section id="blog">
        <div className="container">
            <div className="blog_wrapper">
                <div className="blog_col">
                    <h3>We Help Business Owners to Scale Up Effortlessly</h3>
                    <p>Most businesses bleed due to mismanagement and wrong choices, With us you will lead your business with confidence.</p>
                    <div className="btn_wrapper">
                        <a className="btn" href="https://wa.me/+919619905777?text=RFQ%20Enquiry%20-%20Coach%20Evangelin%20please%20send%20me%20quick%20Quotes">Let's Talk</a>
                    </div>
                </div>
                <div className="blog_col">
                    <div className="blog_image">
                        <img src={BlogImage} alt="Blog" />
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
