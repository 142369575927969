import React from 'react';
import './About.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBusinessTime, faChartPie, faUserClock, faHouseLaptop, faPhone, faBullhorn, faCheck} from '@fortawesome/free-solid-svg-icons';
import AboutImage from '../../assets/banner/about-main.jpeg';

export default function About() {
    // Icons
    const faBusinessIcon = <FontAwesomeIcon icon={faBusinessTime} />
    const faChartPieIcon = <FontAwesomeIcon icon={faChartPie} />
    const faBullhornIcon = <FontAwesomeIcon icon={faBullhorn} />
    const faUserClockIcon = <FontAwesomeIcon icon={faUserClock} />
    const faHouseLaptopIcon = <FontAwesomeIcon icon={faHouseLaptop} />
    const faPhoneIcon = <FontAwesomeIcon icon={faPhone} />
    const faCheckIcon = <FontAwesomeIcon icon={faCheck} />


  return (
    <>
    <section id="about">
        <div class="container">

            <div className="about_box_details">
                <div className="about_col">
                    <div className="about_image">
                        <img src={AboutImage} alt="about" className="about_main"/>
                    </div>
                    <div className="img_info__box">
                        <h6 className="img_info__title">GET A PRICE QUOTE TODAY!</h6>
                        {/* <p>102, B Wing, KANARA BUSINESS CENTER, <br/>Ghatkopar East, Mumbai, Maharashtra 400075</p> */}
                        <a href="tel:+919619905777">{faPhoneIcon} <span>+91 96199 05777</span></a>
                    </div>
                </div>
                <div className="about_col more_space">
                    <h3>Coach Evangelin</h3>
                    <p>Evangelin Gladin is a highly experienced and reputable business coach, dedicated to helping businesses overcome challenges and reach their full potential. With a holistic approach that focuses on leadership development, team building, and goal setting, Evangelin offers strategic guidance to drive growth and optimize operations. By choosing Evangelin as your coach, you gain a dedicated partner committed to your success, built on trust and integrity. Connect with Evangelin to embark on a journey of empowerment and transformation for your business.</p>

                    <div className="grid_info">
                        <div className="icon">{faCheckIcon}</div>
                        <h4>Micro and Macro Niche Crafting</h4>
                    </div>
                    <div className="grid_info">
                        <div className="icon">{faCheckIcon}</div>
                        <h4>Creating Your Road to Success</h4>
                    </div>
                    <div className="grid_info">
                        <div className="icon">{faCheckIcon}</div>
                        <h4>Mission & Vision Statement Crafting</h4>
                    </div>
                    {/* <div className="grid_info">
                        <div className="icon green_icon">{faCheckIcon}</div>
                        <div className="detail">
                            <h4>MISSION & VISION STATEMENT CRAFTING </h4>
                            <p>Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit.</p>
                        </div>
                    </div> */}
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
