import React from 'react';

import './Marketing.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBusinessTime, faChartPie, faUserClock, faHouseLaptop, faPhone, faBullhorn} from '@fortawesome/free-solid-svg-icons';

export default function About() {
    // Icons
    const faBusinessIcon = <FontAwesomeIcon icon={faBusinessTime} />
    const faChartPieIcon = <FontAwesomeIcon icon={faChartPie} />
    const faBullhornIcon = <FontAwesomeIcon icon={faBullhorn} />
    const faUserClockIcon = <FontAwesomeIcon icon={faUserClock} />
    const faHouseLaptopIcon = <FontAwesomeIcon icon={faHouseLaptop} />
    const faPhoneIcon = <FontAwesomeIcon icon={faPhone} />

  return (
    <>
    <section id="marketing">
        <div class="container">
            <div className="title_headling">
                <h3>Media & Marketing Services</h3>
                <p>Want a thriving brand on social media? Eye-catching video content? A profesional website? We can help!</p>
            </div>
            <div className="about_box_wrapper">
                <div className="about_box">
                    <div className="about_icon">
                        {faBusinessIcon}
                    </div>
                    <div className="about_content">
                        <h5>Creative All-in-One Marketing Partner</h5>
                        <p>We are your marketing partner trust us for your marketing strategy and execution of your strategy, be it branding services like website building, market place listing or e-commerce store we are one stop solutions for all your services in digital realm.</p>
                    </div>
                </div>
                <div className="about_box">
                    <div className="about_icon green_icon">
                        {faChartPieIcon}
                    </div>
                    <div className="about_content">
                        <h5>Marketing Consultation</h5>
                        <p>You have your team we can give you marketing strategy to execute through your team members be if whats app bulk messaging, email marketing or any ATL, BTL activities.</p>
                    </div>
                </div>
                <div className="about_box">
                    <div className="about_icon blue_icon">
                        {faBullhornIcon}
                    </div>
                    <div className="about_content">
                        <h5>Media Production</h5>
                        <p>We deliver high quality trendy video, be it for your Ads and branding or Product Portfolio Video Shoots.</p>
                    </div>
                </div>
            </div>

        </div>
    </section>
    </>
  )
}
