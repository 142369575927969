import React from 'react';
import './Home.css';
import BannerImage from '../../assets/banner/evan-banner.jpeg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faShieldHalved, faChartArea } from '@fortawesome/free-solid-svg-icons';
import TestModel from '../Test Banner/TestModel';
import { useState } from 'react';

export default function Home() {
    const [ShowBanner, setShowBanner] = useState(false)

    // Icons
    const faChartIcon = <FontAwesomeIcon icon={faChartLine} />
    const faShieldIcon = <FontAwesomeIcon icon={faShieldHalved} />
    const faChartAreaIcon = <FontAwesomeIcon icon={faChartArea} />


  return (
    <>
    <section id="home">
        {ShowBanner && <TestModel  onClose={() => setShowBanner(false)}/>}
        <div className="banner_image"></div>
        <div className="container">
            <div className="banner_outer">
                <div className="col">
                    <h3 className="title">
                    Your Vision, <br></br><span> Our Passion</span>
                    </h3>
                    <p>With the right coach, Live meaningful life, ask how?</p>
                    <div className="btn_wrapper">
                        <a className="btn" href="https://wa.me/+919619905777?text=RFQ%20Enquiry%20-%20Coach%20Evangelin%20please%20send%20me%20quick%20Quotes">Let's Talk</a>
                        <button className="btn-test" onClick={() => setShowBanner(true)}>Take Personality Test</button>
                    </div>
                    
                </div>
                <div className="col">
                    <div className="sub_banner_image">
                        <img src={BannerImage} alt="Banner_image" />
                    </div>
                    <div className="banner_style_1">
                        {faChartIcon}
                        <h4>Business Analysis</h4>
                    </div>
                    <div className="banner_style_1 banner_style_2">
                        {faShieldIcon}
                        <h4>99.9% Success</h4>
                    </div>
                    <div className="banner_style_1 banner_style_3">
                        {faChartAreaIcon}
                        <h4>Strategy</h4>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}
